export async function unifyPaginatedResponse(methodWithPaginatedResponse, extraParam = null)
{
    let page = 1
    let resources = await makeRequest(methodWithPaginatedResponse, page, extraParam)

    if (!resources) { 
        return [] 
    }

    const totalPages = parseInt(resources.total / resources.per_page) + 1
    const promisesList = Array.from({ length: totalPages - 1 }, (_, i) => i + 2).map((numPage) => {
        return makeRequest(methodWithPaginatedResponse, numPage, extraParam)
    })
    let allResponses = (await Promise.all(promisesList)).map(r => r.data)
    allResponses.unshift(resources.data)
    return allResponses.flat()
}

function makeRequest(methodWithPaginatedResponse, page, extraParam = null)
{
    return extraParam ?
        methodWithPaginatedResponse(extraParam, page) :
        methodWithPaginatedResponse(page);
}


